var windowChildren = {}

var OpenUtil = (function () {

    let lastOpenChat = new Date().getTime();

    return {
        openMemoByMemoSrno: openMemoByMemoSrno,
        openMessengerByRoomSrno: openMessengerByRoomSrno,
        openMessengerByOneUserId: openMessengerByOneUserId,
        openMessengerByProjectSrno: openMessengerByProjectSrno,
        openMessengerByFavoriteGroupCode: openMessengerByFavoriteGroupCode,
        openMessengerByInvite: openMessengerByInvite,
        openMessengerByDvsnCode: openMessengerByDvsnCode,
        openMini: openMini,
        openWindow: openWindow,
        openSubScreen: openSubScreen,
        openGanttChart: openGanttChart,
        openPopup: openPopup,
        openUrlOnBrowser: openUrlOnBrowser,
        getWhljJson: getWhljJson,
        openCollaboEvaluationPopup: openCollaboEvaluationPopup,
        openTelecommuteReportPopup: openTelecommuteReportPopup,
        openPbSalesPopup: openPbSalesPopup,
        openLetterMain: openLetterMain,
        openKogasChatBot: openKogasChatBot,
        openLetterDetail: openLetterDetail,
        openLetterProfile: openLetterProfile,
        open4nbPopup: open4nbPopup,
        setCenterPositionOfWindow: setCenterPositionOfWindow,
    }

    function openGanttChart(projectSrno) {
        if (!PlanFunc.isPlanFuncWithPopup("GANTT", "timeline")) return;
        // if (LimitGuest.isLimitGuest("timeline")) return; //간트 팝업 미존재 > 모아보기와 동일 처리 => left에서 위치 이동
        var target = "gantt_chart";
        const isElectron = ElectronApi.Comm.isElectron();
        if (!isElectron) openPopup("", "_parent");
        projectSrno = Often.null2Void(projectSrno);
        Often.submitForm(target, "gantt_chart.act", target, {
            USER_ID: _USER_ID,
            PRFL_PHTG: _PRFL_PHTG,
            COLABO_SRNO: projectSrno,
            STTS: "S", // 비즈니스 업그레이드 설정 해야 됨
        });
        if (isElectron) {
            var ganttWhljJson = getWhljJson("GANTT");
            var posX = window.screenX ? window.screenX : window.screenLeft;
            var posY = window.screenY ? window.screenY : window.screenTop;
            ElectronApi.Window.setPopupSize({target, ...ganttWhljJson, posX, posY});
            // Electron.setWindowSize(target, ganttWhljJson.width, ganttWhljJson.height, screenX + 10, screenY + 10);
        }
    }

    function openSubScreen(dataJson) {
        let url, target;
        var subScreenWhltJson;
        if (dataJson.GB === "GOOGLE_DRIVE") {
            url = "flow-renewal/view/subscreen_view.jsp?GB=GOOGLE_DRIVE";
        } else {
            url = "subscreen.act?GB=" + dataJson.GB;
        }

        const isElectron = ElectronApi.Comm.isElectron();
        const roomSrno = Often.null2Void(window._ROOM_SRNO);
        if (isElectron && roomSrno.length > 0) {
            target = dataJson.GB + "_" + roomSrno;
        } else {
            target = dataJson.GB;
        }
        if (dataJson.GB2 === "SH") {
            subScreenWhltJson = $.extend({}, getWhljJson("PROFILE_SEARCH"));
        }else{
            subScreenWhltJson = $.extend({}, getWhljJson(dataJson.GB));
        }

        openPopup('', target, subScreenWhltJson);

        if (dataJson.GB === "PROFILE" && ServerChecker.isPsnm) {
            subScreenWhltJson = setCenterPositionOfWindow(subScreenWhltJson);
        }

        Often.submitForm(dataJson.GB, url, target, dataJson, subScreenWhltJson);
        if (isElectron && dataJson.GB === "PROFILE") ElectronApi.Window.setPopupSize({
            target: "PROFILE",
            ...subScreenWhltJson,
            posX: subScreenWhltJson.left,
            posY: subScreenWhltJson.top
        });
    }

    function openMini() {

        var checkCnt = 0;
        var downloadCheckInterval = setInterval(downLoadCheck, 500);

        location.href = ServerChecker.isReal ? 'flownew://' : 'flowRenewalAppLink://';

        $(window).on('blur', function () {
            clearInterval(downloadCheckInterval);
        });

        function downLoadCheck() {
            if (++checkCnt > 2) {
                clearInterval(downloadCheckInterval);
                PopupDraw.drawConfirm({
                    contents: {
                        main: i18next.t(main.alert.installFlow),
                    },
                    callback: {
                        submit: DownloadApp.desktopDownloadDirect,
                    }
                })

            }
        }
    }

    /**
     * 프로젝트 번호로 메세지 창 열기
     * @param projectSrno 프로젝트 번호
     */
    function openMessengerByProjectSrno(projectSrno) {
        var jsonData = {COLABO_SRNO: projectSrno};
        jsonData.packetOption = Ajax.OPTION.PREVENT_EXECUTE;
        Ajax.executeApi(RestApi.POST.COLABO2_CHAT_C001, jsonData, function (data) {
            openMessengerByRoomSrno(data.ROOM_SRNO);
        });
    }

    function openMessengerByOneUserId(userId) {
        const inputJson = {"DVSN_CD": userId};
        const jsonData = {SENDIENCE_REC: [{IN_RCVR_USER_ID: userId}], packetOption : Ajax.OPTION.PREVENT_EXECUTE};
        const callback = () => {
            Ajax.executeApi(RestApi.POST.COLABO2_CHAT_C001, jsonData, function (data) {
                openMessengerByRoomSrno(data.ROOM_SRNO);
                if (!(ElectronApi.Comm.isElectron() && Often.isAct("subscreen"))) return;
                self.close();
            });
        }
        if (Often.isFunc("AUTH_DVSN_GRP")) {
            Ajax.executeApi("FLOW_DVSN_GRP_R001", inputJson, function (data) {
                var isSame = data.IS_SAME_GRP;
                if (isSame === "NO") {
                    Mutil.toast("error", "교류금지정보 공유주의\n(정보교류차단장치운영지침 참조)");
                }
                callback();
                return;
            });
        }
        callback();
    }

    function openMessengerByFavoriteGroupCode(groupCode) {
        var jsonData = {GROUP_CD: groupCode, RENEWAL_YN: 'Y'}
        jsonData.packetOption = Ajax.OPTION.PREVENT_EXECUTE;
        Ajax.executeApi(RestApi.POST.COLABO2_CHAT_C002, jsonData, function (dat) {
            openMessengerByRoomSrno(dat.ROOM_SRNO);
        })
    }

    function openMessengerByDvsnCode(dvsnCode) {
        var jsonData = {DVSN_CD: dvsnCode}
        jsonData.packetOption = Ajax.OPTION.PREVENT_EXECUTE;
        Ajax.executeApi(RestApi.POST.COLABO2_CHAT_C002, jsonData, function (dat) {
            openMessengerByRoomSrno(dat.ROOM_SRNO);
        })
    }

    function openMessengerByInvite(roomSrno, sendienceRec, dvsnRec, groupRec, bizplayGroupRec, inviteType) {
        var jsonData = {
            SENDIENCE_REC: sendienceRec,
            DVSN_REC: dvsnRec,
            GROUP_REC: groupRec,
            BIZPLAY_GROUP_REC: bizplayGroupRec,
            ROOM_SRNO: roomSrno,
        };
        jsonData.packetOption = Ajax.OPTION.PREVENT_EXECUTE;
        const apiKey = Often.isFunc(Func.CLOUD.NEW_CHAT_INVITATION) ?
            RenewalApi.POST.ACT_NEW_CHAT_INVITATION : RestApi.POST.COLABO2_CHAT_C001;
        Ajax.executeApi(apiKey, jsonData, function (data) {
            if (roomSrno === data.ROOM_SRNO) {
                if (data.SENDIENCE_REC.length === 0) {
                    Often.toast("error", i18next.t(main.alert.alreadyExist, {val: $t(dictionary.employee)}));
                    return;
                }

                MessengerApi.inviteMessenger(data, function () {
                    setTimeout(function () {
                        if (inviteType.isGroupCall) window.opener.postMessage({
                            msg: 'message7',
                            list: data.SENDIENCE_REC
                        });
                        selfScreenClose();
                    }, 500);
                });
                return;
            }

            Chatting.getRoomInfo({"isExecute": true, "roomSrno": data.ROOM_SRNO, "callback": function (dat) {
                openMessengerByRoomSrno(data.ROOM_SRNO);
                if (dat.READ_OVER_YN !== 'N') MessengerApi.readMessage({roomSrno});
                if (dat.ROOM_GB !== RoomType.ONE_TO_ONE) {
                    SocketControl.sendMessage({
                        CHAT_CODE: SocketAPI.CONNECT.MESSENGER.CREATE,
                        ROOM_SRNO: CONNECT_ID,
                        CHATTING_ROOM_SRNO: data.ROOM_SRNO,
                        CHAT_DATA: data,
                    }, selfScreenClose);
                } else {
                    selfScreenClose();
                }
            }})

            /**
             * @description socket을 받기전에 close 될 수 있음
             */
            function selfScreenClose() {
                self.close();
            }
        });
    }

    function openMemoByMemoSrno(memoSrno) {
        var url = '/subscreen.act?GB=MEMO&memo_srno=' + memoSrno;
        var target = "MEMO_" + memoSrno;
        var messengerWhltJson = $.extend({}, getWhljJson("MEMO"), {memoSrno});
        openPopup(url, target, messengerWhltJson, ELECTRON_OPEN.MEMO)
    }

    function findMainWindow() {
        let _w = window, cycle;
        for (let i = 0; i < 5; i++) {
            cycle = i;
            if (_w?.location?.pathname?.includes('main.act') || _w?.location?.pathname?.includes('portal.act')) break;
            _w = _w.opener;
        }
        return {mainWindow: _w, cycle};
    }

    /***
     * 채팅방 번호로 채팅방 열기
     * @param roomSrno 채팅 방 넘버
     * @param roomChatSrno
     * @param isGroupcall
     */
    function openMessengerByRoomSrno(roomSrno, roomChatSrno, isGroupcall) {
        roomChatSrno = Often.null2Void(roomChatSrno)
        const {ie: isInternetExplorer, safari: isSafari, firefox: isFirefox} = Often.isBrowser();
        const isElectron = ElectronApi.Comm.isElectron();
        const url = '/messenger.act?room=' + btoa(roomSrno) + '&chat=' + btoa(roomChatSrno) + '&time=' + new Date().getTime();
        const popupName = "POPUP_CHAT_" + roomSrno;
        const messengerWhltJson = getWhljJson("MESSENGER");

        if (isInternetExplorer || isSafari || isFirefox) {
            openPopup(url, popupName, messengerWhltJson, ELECTRON_OPEN.CHAT);
            return;
        }

        if (isElectron) {
            checkLastOpenChat().then(() => {
                openPopup(url, popupName, messengerWhltJson, ELECTRON_OPEN.CHAT, isGroupcall);
            });
            return;
        }

        const isChatInChat = !!location?.pathname?.includes('messenger.act');
        if (isChatInChat) {
            const {mainWindow} = findMainWindow();
            mainWindow?.OpenUtil?.openMessengerByRoomSrno(roomSrno, roomChatSrno, isGroupcall);
            if (roomSrno === window._ROOM_SRNO) {
                Mutil.toast("info", i18next.t(main.alert.openedChat));
            } else {
                if (mainWindow?.windowChildren[popupName]?.location.href) {
                    window.open('', popupName);
                }
            }
            return;
        }

        const windowChildrenKeys = Object.keys(windowChildren);
        const isStepPopup = windowChildrenKeys.length > 0;
        const openOnlyOneMessenger = Often.isFunc("OPEN_ONLY_ONE_MESSENGER");

        if (isStepPopup && !openOnlyOneMessenger) {
            for (let key in windowChildren) {
                const _window = windowChildren[key];
                if (_window) {
                    const {screenLeft} = _window;
                    const {left} = messengerWhltJson;
                    const isSimilarX = Math.abs(screenLeft - left) < 5;
                    if (popupName === key) {
                        //pass
                    } else {
                        focusWindowPop(key);
                    }

                    if (isSimilarX) {
                        messengerWhltJson.top += 30;
                        messengerWhltJson.left += 30;
                    }
                } else {
                    delete windowChildren[popupName];
                }
            }
        }

        focusWindowPop(popupName);

        function isNotMatchingPopNContent(_targetWindow) {
            const isExistsRoomSrno = !isNaN(+(_targetWindow._ROOM_SRNO || 'NONE'));
            return isExistsRoomSrno && !_targetWindow.name.includes(_targetWindow._ROOM_SRNO);
        }

        function focusWindowPop(popupName) {
            if(windowChildren[popupName]?.closed) {
                delete windowChildren[popupName];
                return;
            }

            const _targetWindow = windowChildren[popupName];
            if (!_targetWindow) {
                openMessenger(popupName).then();
                return;
            }

            if (!_targetWindow.location.href || isNotMatchingPopNContent(_targetWindow)) {
                delete windowChildren[popupName];
                return;
            }

            window.open('', popupName);
        }

        async function checkLastOpenChat() {
            //채팅방을 오픈하지 않은지 오래된 상태인가? 예) 10분
            const oldChatNoOpenedSec = Math.floor((new Date().getTime() - lastOpenChat) / 1000);
            const isOldChatNoOpened = oldChatNoOpenedSec > (10 * 60); //10분
            if (isOldChatNoOpened) {
                await Promise.allSettled([
                    AlarmUpdate.drawAsyncTopNotReadCount(),
                    Chatting.drawAsyncTopNotReadCount()
                ]);
                Mutil.mlog("채팅방을 " + oldChatNoOpenedSec + "초만에 열었고 갱신했습니다.");
            } else {
                Mutil.mlog("채팅방을 " + oldChatNoOpenedSec + "초만에 열었습니다.");
            }
            lastOpenChat = new Date().getTime();
        }

        async function openMessenger(popupName) {
            if (isNotMatchingPopNContent(popupName)) {
                return;
            }

            await checkLastOpenChat();
            const {ROOM_KIND: roomKind = ''} = LocalUtil.getLocalJson("ONLY_MESSENGER-" + roomSrno) || {};
            windowChildren[popupName] = openPopup('', popupName, messengerWhltJson);
            Often.submitForm(popupName, '/messenger.act', popupName, {
                room: btoa(roomSrno),
                chat: btoa(roomChatSrno),
                roomKind,
                time: new Date().getTime(),
                USER_ID: window._USER_ID,
                RGSN_DTTM: window._RGSN_DTTM,
            });
        }
    }

    function getWhljJson(mode) {
        var isWindow7 = Often.getClientOSInfo().isWin7;
        var isChat = ['INVITE_CHAT', 'NEW_CHAT', 'INVITE_CHAT_GROUP_CALL'].includes(mode);
        var whltJson;
        if (['IMAGE_VIEWER', 'SIMPLE_IMAGE_VIEWER', 'STREAM_VIEWER', 'LETTER_IMAGE_VIEWER'].includes(mode)) {
            whltJson = {width: 820, height: 720}
        } else if ('CHAT_COLLECTION' === mode) {
            whltJson = {width: 640, height: 780}
        } else if ('GOOGLE_DRIVE' === mode || 'DROPBOX_DRIVE' === mode) {
            whltJson = {width: 620, height: 720}
        } else if ('MESSENGER' === mode) {
            whltJson = {width: 450, height: 780}
        } else if ('MINI' === mode) {
            whltJson = {width: 450, height: 660}
        } else if ('GANTT' === mode) {
            whltJson = {width: 1366, height: 768}
        } else if ('CHAT_LONG_TEXT' === mode || 'CHAT_NOTICE_TEXT' === mode) {
            whltJson = {width: 420, height: 640}
        } else if ('MEMO' === mode) {
            whltJson = {width: 350, height: 350}
        } else if (isChat && ElectronApi.Comm.isElectron()) {
            whltJson = {width: 640, height: 700, resizable: 'no'}
        } else if (isChat) {
            whltJson = {width: 650, height: 700, resizable: 'no'}
        } else if ('serviceMap' === mode) {
            whltJson = {width: 640, height: 680, resizable: 'no'}
        } else if ('EMAIL' === mode) {
            whltJson = {width: 640, height: 680, resizable: 'no'}
        } else if ('majorService' === mode) {
            whltJson = {width: 1045, height: 1000}
        } else if ('FAVORITE' === mode) {
            whltJson = {width: 640, height: 680, resizable: 'no'}
        } else if ('FAVORITE' === mode) {
            whltJson = {width: 430, height: 660, resizable: 'no'}
        } else if ('PROFILE' === mode || 'PROFILE_SEARCH' === mode) {
            whltJson = calMiniProfileSize(mode);
        } else if ('POST_LINK' === mode) {
            whltJson = {width: 674, height: 768, resizable: 'no'}
        } else if ("flowDrive" === mode) {
            whltJson = {width: 1100, height: 800}
        } else if ("SMS" === mode) {
            whltJson = {width: 920, height: 600, minWidth: 936, minHeight: 638}
        }  else if ("CHAT_TOTAL_SEARCH" === mode) {
            whltJson = {width: 640, height: 780}
        } else if ("SMS_WRITE" === mode) {
            whltJson = {width: 700, height: 735, minWidth: 716, minHeight: 773}
        } else if ("SMS_DETAIL" === mode) {
            whltJson = {width: 702, height: 700, minWidth: 718, minHeight: 738}
        } else if ("SMS_RECEIVER_DETAIL" === mode) {
            whltJson = {width: 434, height: 600, minWidth: 450, minHeight: 638}
        } else if ("USER_MANAGEMENT" === mode) {
            whltJson = {width: 1300, height: 1000}
        } else if ("CHAT_TOTAL_SEARCH" === mode) {
            whltJson = {width: 640, height: 780}
        } else if ("FORWARD_CONTENTS" === mode) {
            whltJson = {width: 640, height: 780}
        } else if ("COLLABO_EVALUATION" === mode) {
            whltJson = {
                width: 600,
                height: 720,
                option: ",toolbar=no,menubar=no,location=no,scrollbars=no,status=no,resizable=no"
            }
        } else if ("TELECOMMUTE_REPORT" === mode) {
            whltJson = {
                width: 1080,
                height: 900,
                option: ",toolbar=no,menubar=no,location=no,scrollbars=no,status=no,resizable=no"
            }
        } else if ("PB_BUSINESS" === mode) {
            whltJson = {width: 400, height: 640, option: ",directories=no, status=no, scrollorbars=no, resizable=no"}
        } else if ('GROUP_CALL' === mode) {
            whltJson = {
                width: 430,
                height: 270,
                option: ",toolbar=no,menubar=no,location=no,scrollbars=no,status=no,resizable=no"
            }
        } else if ("PHONE_DIAL" === mode) {
            whltJson = {
                width: 400,
                height: 700,
                option: ",toolbar=no,menubar=no,location=no,scrollbars=no,status=no,resizable=no"
            }
        } else if ("LETTER" === mode) {
            if (ElectronApi.Comm.isElectronApp()) {
                whltJson = {width: 872, height: 630, option: ",toolbar=no,menubar=no,location=no"}
            } else {
                whltJson = {width: 852, height: 600, option: ",toolbar=no,menubar=no,location=no"}
            }
        } else if ("CHATBOT" === mode) {
            if (ElectronApi.Comm.isElectronApp()) {
                whltJson = {width: 470, height: 800, option: ",toolbar=no,menubar=no,location=no"}
            } else {
                whltJson = {width: 882, height: 600, option: ",toolbar=no,menubar=no,location=no"}
            }
        }else if ("LETTER_SEND" === mode) {
            if(_USE_INTT_ID.startsWith("KOGAS")){
                if (ElectronApi.Comm.isElectronApp()) {
                    whltJson = {width: 770, height: 740, option: ",toolbar=no,menubar=no,location=no"}
                } else {
                    whltJson = {width: 770, height: 720, option: ",toolbar=no,menubar=no,location=no"}
                }
            } else {
                let size = Often.isFunc('MESSAGE_WRITE_MEMORY') ? LocalUtil.getLocalJson('ONLY_LETTER_SEND_SIZE') : {};
                const defaultLetterSendHeight = Often.isFunc('MESSAGE_HIDE_REFERENCE') ? "775" : "720";
                if (ElectronApi.Comm.isElectronApp()) {
                whltJson = {width: +Often.null2Void(size?.width, "710"), height: +Often.null2Void(size?.height, `${+defaultLetterSendHeight + 20}`), option: ",toolbar=no,menubar=no,location=no"}
                } else {
                whltJson = {width: +Often.null2Void(size?.width, "700") , height: +Often.null2Void(size?.height, defaultLetterSendHeight), option: ",toolbar=no,menubar=no,location=no"}
                }
            }
        } else if ("LETTER_DETAIL" === mode) {
            if(_USE_INTT_ID.startsWith("KOGAS")){
                if (ElectronApi.Comm.isElectronApp()) {
                    whltJson = {width: 770, height: 740, option: ",toolbar=no,menubar=no,location=no"}
                } else {
                    whltJson = {width: 770, height: 720, option: ",toolbar=no,menubar=no,location=no"}
                }
            } else {
                const defaultLetterSendHeight = Often.isFunc('MESSAGE_HIDE_REFERENCE') ? "775" : "720";
                if (ElectronApi.Comm.isElectronApp()) {
                    whltJson = {width: 710, height: defaultLetterSendHeight + 20, option: ",toolbar=no,menubar=no,location=no"}
                } else {
                    whltJson = {width: 700, height: defaultLetterSendHeight, option: ",toolbar=no,menubar=no,location=no"}
                }
            }
        } else if ("IptCallList" === mode) {
            whltJson = {width: 434, height: 670}
        } else if (mode.indexOf("MANAGER_ADMIN") > -1) {
            whltJson = {width: 1500, height: 1000, resizable: "yes", frame: true}
        } else if ("KYOWON_IPT" === mode) {
            whltJson = {width: 350, height: 200}
        } else if ("4NB_POPUP" === mode) {
            whltJson = {width: 500, height: 800, option: ",toolbar=no,menubar=no,location=no"}
        } else if ('CALENDAR' === mode) {
            whltJson = {width: 1366, height: 768, resizable: "yes", frame: true}
        } else if ("ORG_SEARCH" === mode) {
            whltJson = {
                width: 1150,
                height: 670,
                resizable: "no",
                option: ",location=no,status=no",
                frame: true
            }
        } else if ("USER" === mode) {
            whltJson = {width: 640, height: 680, resizable: 'no'}
        } else {
            whltJson = {width: 820, height: 720}
        }

        if (['CHAT_COLLECTION', 'INVITE_CHAT', 'NEW_CHAT', 'PROFILE', 'FAVORITE', 'INVITE_CHAT_GROUP_CALL'].includes(mode) ||
            ['IMAGE_VIEWER', 'SIMPLE_IMAGE_VIEWER', 'CHAT_LONG_TEXT', 'LETTER_IMAGE_VIEWER'].includes(mode) ||
            ['POST_LINK', 'MEMO', 'STREAM_VIEWER', 'CHAT_NOTICE_TEXT'].includes(mode) ||
            mode.indexOf('_DRIVE') > -1
        ) {
            whltJson.left = convertMinZero(Number(window.screenLeft) + Number(window.outerWidth));
            whltJson.top = convertMinZero(window.screenTop);
        } else if (['PROFILE_SEARCH'].includes(mode)) {
            whltJson.left = convertMinZero(window.screenLeft + ($(window).width() - whltJson.width) / 2);
            whltJson.top = convertMinZero(window.screenTop + ($(window).height() - whltJson.height) / 2);
        } else if (['EMAIL'].includes(mode)) {
            whltJson.left = convertMinZero(window.screenLeft + ($$(window).width() - whltJson.width) / 2);
            whltJson.top = convertMinZero(window.screenTop + ($$(window).height() - whltJson.height) / 2);
        }
        else {
            whltJson.left = convertMinZero(window.screenLeft + ($(window).width() - whltJson.width) / 2);
            whltJson.top = convertMinZero(window.screenTop + ($(window).height() - whltJson.height) / 2);
        }
        return whltJson;

        function convertMinZero(value) {
            return (isWindow7 && value < -1) ? 0 : value;
        }
    }

    /**
     * @fix : ElectronApi.Letter.open으로 변경필요
     */
    function openPopup(url, target, whltJson, gubun, kind) {
        let isWindowOpenFunc = target === "GROUP_CALL";
        let isElectron = ElectronApi.Comm.isElectronAvailable();
        if (target === "Sms" || target === "EMAIL" || target === 'serviceMap') {
            isElectron = false;
        }

        if (url && isElectron && !isWindowOpenFunc) {
            if (gubun && gubun.indexOf("CHATBOT") > -1) {
                let encrypted = encodeURIComponent(CryptoJS.AES.encrypt(_USER_ID, 'kogas123?').toString());
                ElectronApi.Window.openUniquePopup({
                    url: url + '?userId=' + encrypted,
                    title: target,
                    width: whltJson.width,
                    height: whltJson.height,
                    x: whltJson.left,
                    y: whltJson.top,
                    options: {
                        isPreload: true,
                        isNotResizeable: true
                    },
                    minWidth: whltJson.width,
                    minHeight: whltJson.height,
                });
                return;
            } else {
                url = Often.getLocOrigin() + url;
                const props = {url, target, ...whltJson, posX: whltJson.left, posY: whltJson.top, kind};
                if (ELECTRON_OPEN.CHAT === gubun) {
                    ElectronApi.Chat.open({
                        ...props,
                        frame: !Often.isFunc("CHAT_TRANSPARENT")
                    });
                }
                else if (gubun && (gubun.indexOf("LETTER") > -1 || gubun.indexOf("SMS") > -1 || gubun.indexOf("USER_MANAGEMENT") > -1)) {
                    if (!(Often.isFunc("MESSAGE_POPUP") || Often.isFunc("SMS_POPUP"))) return;
                    const isElectronSms = gubun.indexOf("SMS") > -1;
                    window.ElectronWindowApi.openUniquePopup({
                        url,
                        title: target,
                        width: whltJson.width,
                        height: whltJson.height,
                        x: whltJson.left,
                        y: whltJson.top,
                        options: {
                            isPreload: true,
                        isNotResizable: !Often.isFunc('MESSAGE_WRITE_MEMORY'),
                        },
                    minWidth: isElectronSms ? whltJson.minWidth : '710',
                    minHeight: isElectronSms ? whltJson.minHeight : '740'
                    })
                } else if (ELECTRON_OPEN.MEMO === gubun) {
                    ElectronApi.Thirdparty.memo.open(props)
                } else {
                    ElectronApi.Window.open(props);
                }
                return;
            }
        }
        let specs = getSpecs(whltJson);
        if (whltJson && whltJson.option) specs += whltJson.option;

        if (isWindowOpenFunc && isElectron) GroupCall.openPopUp(url, target, specs);

        if (Often.isFunc('WINDOW_OPEN_MEMORY')) {
            window.open(url, target, specs);
            return {
                screenLeft: whltJson.left,
            };
        } else {
            return window.open(url, target, specs);
        }
    }

    function openUrlOnBrowser(tempUrl, target) {
        target = target || tempUrl;
        if (ElectronApi.Comm.isElectron()) {
            return ElectronApi.Window.openExternalLink({url: tempUrl});
        }
        openWindow(tempUrl, target);
    }

    function getSpecs(whltJson = {}) {
        if(Mutil.empty(whltJson)) {
            return "";
        }
        return `width=${whltJson.width }px, height=${whltJson.height}px, top=${whltJson.top}, left=${whltJson.left}, resizable=${Often.null2Void(whltJson.resizable, 'yes')}`;
    }

    /**
     * 주어진 URL을 새 창으로 엽니다. 
     * Electron 환경에서는 외부 링크로 열고, Safari에서 내부 URL일 경우 팝업을 사용합니다.
     * 
     * @param {string} url - 열고자 하는 URL입니다.
     * @param {string} target - 새 창의 이름 또는 타겟입니다.
     * @param {Object} [attrJson={}] - 창의 속성을 정의하는 JSON 객체입니다.
     */
    function openWindow(url, target, attrJson = {}) {
        //Note. https://, http:// => // 대체 가능 (크롬, IE 모두)
        const tempUrl = urlClassify(url);
        const isSafari = Often.isBrowser("safari");
        const isInternalUrl = Often.isTinyUrlIncluded(tempUrl);

        if (ElectronApi.Comm.isElectron()) {
            ElectronApi.Window.openExternalLink({url: tempUrl});
        } else if (isSafari && isInternalUrl) {
            // @우성호: [오류] Safari 채팅창에서만 플로우 내부 링크 안열림 (https://flow.team/l/PYn2Z)
            openPopup('', target, attrJson);
            Often.submitForm('', tempUrl, target, {}, {});
        } else {
            const newWindow = window.open(tempUrl, target, getSpecs(attrJson));
            if (!newWindow) {
                Mutil.toast("error", "팝업이 차단되었습니다. 팝업 차단 설정을 확인해주세요."); // 팝업 차단 경고
            }
        }
    }

    function calMiniProfileSize(mode) {
        var whltJson;
        if (ServerChecker.isKrx) {
            if('PROFILE_SEARCH' === mode){
                whltJson = {width: 360, height: 630, resizable: 'no'};// 통합검색 프로필 사이즈
            }else {
                whltJson = {width: 350, height: 758, resizable: 'no'};// D: 엔터-krx일때만 height: 756 적용해주세요
            }
        } else {
            whltJson = {width: 350, height: 656, resizable: 'no'};
        }
        if (Often.isFunc(Func.ENTER.HIDE_PRFL_COMPANY_INFO)) whltJson.height = whltJson.height - 66;
        if (Often.isFunc(Func.ENTER.HIDE_PRFL_EML)) whltJson.height = whltJson.height - 40;
        if (Often.isFunc(Func.ENTER.HIDE_PRFL_CLPH_NO)) whltJson.height = whltJson.height - 40;
        if (Often.isFunc(Func.ENTER.HIDE_PRFL_CMPN_TLPH_NO)) whltJson.height = whltJson.height - 40;
        if (Often.isFunc(Func.ENTER.HIDE_PRFL_SLGN)) whltJson.height = whltJson.height - 44;
        // if (Often.isFunc(Func.ENTER.SHOW_FMC_NO)) whltJson.height += 45;
        // if (Often.isFunc(Func.ENTER.SHOW_EXT_NO)) whltJson.height += 45;
        // if (Often.isFunc(Func.ENTER.SHOW_DAY_OFF)) whltJson.height += 45;
        // if (Often.isFunc(Func.ENTER.SHOW_WORKING_TIME)) whltJson.height += 45;
        // if (Often.isFunc(Func.ENTER.SHOW_CHARGE_JOB_NM)) whltJson.height += 45;
        // if (Often.isFunc(Func.ENTER.SHOW_EMP_NO)) whltJson.height += 45;
        if (ServerChecker.isJoins) whltJson.height += 45;
        return whltJson;
    }

    /**
     * @Note. http로 전송필요한 경우
     */
    function isNotSecure(url) {
        return url.indexOf("localhost") > -1 ||
            url.indexOf('gw.oto.kr') > -1 || //네오오토 그룹웨어 알림봇
            url.indexOf('166.79.3.20') > -1  // 삼성전기 EDMS
    }

    /**
     * @Note. url(http, https, www) 분류
     */
    function urlClassify(url) {
        var tempUrl = url;
        if (isNotSecure(url)) {
            tempUrl = url.replace("https://", "").replace("http://", "");
            tempUrl = "http://" + tempUrl;
        } else if ((!url.includes('https://') && !url.includes('http://')) && url.indexOf("www") > -1) {
            tempUrl = "//" + tempUrl;
        } else if (url.indexOf("www") > -1 && url.indexOf("http") === -1) {
            tempUrl = "//" + tempUrl;
        }
        return tempUrl;
    }

    function openCollaboEvaluationPopup(colaboSrno, type) {
        if (!Often.isFunc(Func.ENTER.COLABO_EVALUATION)) return;
        var target = "collabo_evaluation";
        var url = "/collabo/COLLABO_EVALUATION_view.jsp";
        var collaboEvaluationWhljJson = OpenUtil.getWhljJson("COLLABO_EVALUATION");
        const isElectron = ElectronApi.Comm.isElectron();

        if (!isElectron) OpenUtil.openPopup("", target, collaboEvaluationWhljJson);
        Often.submitForm(target, url, target, {
            _USER_ID: _USER_ID,
            _RGSN_DTTM: _RGSN_DTTM,
            _USE_INTT_ID: _USE_INTT_ID,
            _COLABO_SRNO: colaboSrno,
            _TYPE: type
        });
        if (isElectron) {
            var posX = screen.width / 2 - collaboEvaluationWhljJson.width / 2;
            var posY = screen.height / 2 - collaboEvaluationWhljJson.height / 2;
            ElectronApi.Window.setPopupSize({target, ...collaboEvaluationWhljJson, posX, posY})
        }
    }

    function openTelecommuteReportPopup(type, _checkedTaskIds) {
        if (!Often.isFunc(Func.ENTER.TELECOMMUTE_REPORT)) return;
        var target = "telecommute_report";
        var url = "/collabo/TELECOMMUTE_REPORT_view.jsp";
        var telecommuteWhljJson = OpenUtil.getWhljJson("TELECOMMUTE_REPORT");
        const isElectron = ElectronApi.Comm.isElectron();

        if (!isElectron) OpenUtil.openPopup("", target, telecommuteWhljJson);
        Often.submitForm(target, url, target, {
            _USER_ID: _USER_ID,
            _RGSN_DTTM: _RGSN_DTTM,
            _USE_INTT_ID: _USE_INTT_ID,
            _TYPE: type,
            _CHECKED_TASKS: JSON.stringify(_checkedTaskIds)
        });
        if (isElectron) {
            var nLeft = screen.width / 2 - telecommuteWhljJson.width / 2;
            var nTop = screen.height / 2 - telecommuteWhljJson.height / 2;
            ElectronApi.Window.setPopupSize({
                target,
                width: telecommuteWhljJson.width,
                height: telecommuteWhljJson.height,
                posX: nLeft,
                posY: nTop
            });
        }
    }

    function openPbSalesPopup(data) {
        if (!Often.isFunc(Func.ENTER.DBFI_OFFICIAL)) return;
        var target = "flow_pb_business";
        var url = "/flow_pb_business.act";
        var pbSalesWhltJson = getWhljJson("PB_BUSINESS");
        const isElectron = ElectronApi.Comm.isElectron();

        if (!isElectron) openPopup("", target, pbSalesWhltJson);
        Often.submitForm(target, url, target, {
            _USER_ID: _USER_ID,
            _RGSN_DTTM: _RGSN_DTTM,
            _USE_INTT_ID: _USE_INTT_ID,
            _SCHEDULE_GB: Often.null2Void(data.scheduleType),
            _CUSTOMER_ID: Often.null2Void(data.customerId),
            _CUSTOMER_TYPE: Often.null2Void(data.customerType),
            _CUSTOMER_INFO: Often.null2Void(data.customerInfo),
            _ACTIVITY_CONTENT: Often.null2Void(data.activityContent),
            _COMPLETE_YN: Often.null2Void(data.completeYn),
            _POST_SRNO: Often.null2Void(data.colaboCommtSrno),
        });
        if (isElectron) {
            var nLeft = screen.width / 2 - pbSalesWhltJson.width / 2;
            var nTop = screen.height / 2 - pbSalesWhltJson.height / 2;
            ElectronApi.Window.setPopupSize({
                target,
                width: pbSalesWhltJson.width,
                height: pbSalesWhltJson.height,
                posX: nLeft,
                posY: nTop
            });
        }
    }

    function openKogasChatBot() {
        const screenSpec = getWhljJson("CHATBOT");
        // const url = 'http://company.i-bricks.co.kr:5248/';
        // const url = 'http://172.19.4.114:3000/';
        const url = 'http://withchat.kogas.or.kr:3000/';
        openPopup(url, '챗봇', screenSpec, "CHATBOT");
    }

    function openLetterMain() {
        let screenSpec = OpenUtil.getWhljJson("LETTER");
        let url = '/letterMain.act';

        //새로운 popup창을 위한 session을 생성
        Ajax.executeApi("COLABO2_USER_PRFL_R009", {"SRCH_USER_ID": _USER_ID}, () => {
            if (!ElectronApi.Comm.isElectronApp()) {
            openPopup('', `${i18next.t(dictionary.noteMessageInbox)}`, screenSpec);
                Often.submitForm(`${i18next.t(dictionary.noteMessageInbox)}`, url, `${i18next.t(dictionary.noteMessageInbox)}`, {
                    TARGET: `${i18next.t(dictionary.noteMessageInbox)}`,
                });
                return;
            }
            openPopup(url + `?TARGET=${i18next.t(dictionary.noteMessageInbox)}`, `${i18next.t(dictionary.noteMessageInbox)}`, screenSpec, "LETTER");
        });
    }

    function openLetterDetail(options) {
        const {
            type,
            target,
            messageSrno = null,
            title = `i18next.t(dictionary.noteMessage)`,
            isSent = false,
            isNew = false,
            isReserve = false,
            isTemporary = false,
            isReadConfirm = false,
            receiverId = "",
            projectId = '',
            postId = '',
        } = options;


        let screenSpec = OpenUtil.getWhljJson(type);
        screenSpec = setCenterPositionOfWindow(screenSpec);

        const url = '/letterDetail.act';
        const queryParams = {
            USER_ID: window._USER_ID,
            TARGET: target,
            MESSAGE_SRNO: messageSrno,
            IS_SENT: isSent,
            IS_NEW: isNew,
            IS_RESERVED: isReserve,
            IS_TEMPORARY: isTemporary,
            IS_READ_CONFIRM: isReadConfirm,
            RECEIVER_ID: receiverId,
            PROJECT_ID: projectId,
            POST_ID: postId,
        };

        Ajax.executeApi("COLABO2_USER_PRFL_R009", {"SRCH_USER_ID": _USER_ID}, () => {
            if (!ElectronApi.Comm.isElectronApp()) {
                openPopup('', `${target}(${messageSrno})`, screenSpec, type);
                Often.submitForm(target, url, `${target}(${messageSrno})`, queryParams);
            } else {
                const queryString = Object.entries(queryParams)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');
                const popupUrl = `${url}?${queryString}`;
                openPopup(popupUrl, `${title}(${messageSrno})`, screenSpec, type);
            }
        });
    }

    function openLetterProfile(id) {
        const getProfileData = () => {
            return new Promise((resolve) => {
                Ajax.executeApi("COLABO2_USER_PRFL_R009", {"SRCH_USER_ID": id}, function (result) {
                    resolve(result);
                });
            });
        }

        getProfileData().then((resolvedData) => {
            if (resolvedData.USER_PRFL_IDSRCH_REC?.length > 0 && resolvedData.USER_PRFL_IDSRCH_REC[0].STTS === 'N') {
                return Often.toast("error", "비활성화 된 계정입니다.");
            }
            const dataJson = {
                GB: 'PROFILE',
                ID: id
            }
            let screenSpec = OpenUtil.getWhljJson(dataJson.GB);
            screenSpec.height += 10;
            screenSpec = setCenterPositionOfWindow(screenSpec);
            let url = "subscreen.act?GB=" + dataJson.GB;
            let openWindow = openPopup('', 'LETTER_PROFILE', screenSpec);
            Often.submitForm('LETTER_PROFILE', url, 'LETTER_PROFILE', dataJson);
            if (ElectronApi.Comm.isElectronApp()|| ElectronApi.Comm.isElectronAvailable()) {
                ElectronApi.Window.setPopupSize({
                    target : "LETTER_PROFILE",
                    ...screenSpec,
                    posX: screenSpec.left,
                    posY: screenSpec.top,
                });
            }
        });
    }

    function open4nbPopup(type, schdData) {
        let screenSpec = OpenUtil.getWhljJson("4NB_POPUP");
        screenSpec = setCenterPositionOfWindow(screenSpec);
        let url = "/flow_create_video_conference.act";

        let vcTtl = schdData.VC_TTL;
        let vcDate = schdData.VC_DATE;
        let vcStartDttm = schdData.VC_START_DTTM;
        let vcEndDttm = schdData.VC_END_DTTM;
        let vcAllDay = schdData.VC_ALLDAY;
        let vcMemo = schdData.VC_MEMO;

        if (!ElectronApi.Comm.isElectronApp()) {
            openPopup('', "4NB_POPUP", screenSpec);
            Often.submitForm('4NB_POPUP', url, "4NB_POPUP", {
                USER_ID: _USER_ID,
                VC_TTL: vcTtl,
                VC_DATE: vcDate,
                VC_START_DTTM: vcStartDttm,
                VC_END_DTTM: vcEndDttm,
                VC_ALLDAY: vcAllDay,
                VC_MEMO: vcMemo,
            });
            return;
        }
        openPopup(url + "?VC_TTL=" + vcTtl + "&VC_DATE=" + vcDate +
            "&VC_START_DTTM=" + vcStartDttm + "&VC_END_DTTM=" + vcEndDttm + "&VC_ALLDAY=" + vcAllDay + "&VC_MEMO=" + vcMemo, "4NB_POPUP", screenSpec, "4NB_POPUP");
    }

    function setCenterPositionOfWindow(screenSpec) {
        screenSpec.top = (window.screen.availHeight - screenSpec.height) / 2 - 10;

        let left = (window.screen.availWidth - screenSpec.width) / 2;
        if (window.screenLeft < 0) {
            left += window.screen.width * -1;
        } else if (window.screenLeft > window.screen.width) {
            left += window.screen.width;
        }

        if(left < 0) left = -left;

        screenSpec.left = left
        return screenSpec;
    }


})();
